// src/Chat.js
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

function Chat({ useRAG }) { // Accept useRAG as a prop
  const [chatLog, setChatLog] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const userMessage = inputValue;
    setChatLog([...chatLog, { sender: 'User', message: userMessage }]);
    setInputValue('');

    try {
	const response = await fetch('/ask/', {
	  method: 'POST',
	  headers: {
	    'Content-Type': 'application/x-www-form-urlencoded',
	  },
	  body: new URLSearchParams({ question: userMessage, useRAG: useRAG ? 'true' : 'false' }),
	});

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setChatLog((prevChatLog) => [
        ...prevChatLog,
        { sender: 'Bot', message: data.response },
      ]);
    } catch (error) {
      console.error('Error:', error);
      setChatLog((prevChatLog) => [
        ...prevChatLog,
        { sender: 'Bot', message: 'Sorry, an error occurred.' },
      ]);
    }
  };

  return (
    <div className="chat-container">
      <h3>Kantar Media Ops Virtual Assistant</h3>
      <div className="chat-box">
        <div className="chat-log">
          {chatLog.map((log, index) => (
            <div key={index} className={`message ${log.sender.toLowerCase()}`}>
              <strong>{log.sender}:</strong> <ReactMarkdown>{log.message}</ReactMarkdown>
            </div>
          ))}
        </div>
        <form onSubmit={handleFormSubmit}>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type your question..."
            required
          />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
}

export default Chat;

