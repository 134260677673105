import React, { useState } from 'react';
import Chat from './Chat';
import FileUpload from './FileUpload';
import './App.css';

export default function Home() {
  const [useRAG, setUseRAG] = useState(false);

  const toggleRAG = () => {
    setUseRAG((prevState) => !prevState);
  };

  return (
    <div className="App">
      <h1>KM-Ops Virtual Assistant</h1>
      <div className="main-container">
        <div className="sidebar">
          <div className="rag-toggle">
            <button onClick={toggleRAG}>
              {useRAG ? 'Deactivate RAG' : 'Activate RAG'}
            </button>
          </div>
          <FileUpload />
        </div>
        <div className="chat-main">
          <Chat useRAG={useRAG} />
        </div>
      </div>
    </div>
  );
}

