import React, { useEffect, useState } from 'react';

function FileUpload() {
  const [files, setFiles] = useState([]);

  const handleFileUpload = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    try {
      const response = await fetch('/upload/', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      alert(result.message);
      fetchIndexedFiles(); // Refresh file list after upload
    } catch (error) {
      console.error('Error:', error);
      alert('File upload failed.');
    }
  };

  const fetchIndexedFiles = async () => {
    try {
      const response = await fetch('/get_indexed_files/');
      const data = await response.json();
      if (data.indexed_files) {
        setFiles(data.indexed_files);
      } else {
        alert('Failed to retrieve indexed files');
      }
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const handleFileDelete = async (filename) => {
    try {
      const response = await fetch('/delete_indexed_file/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filename }),
      });

      const result = await response.json();
      alert(result.message);
      fetchIndexedFiles(); // Refresh file list after deletion
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('File deletion failed.');
    }
  };

  useEffect(() => {
    fetchIndexedFiles(); // Fetch files on component load
  }, []);

  return (
    <div className="upload-panel">
      <h3>Upload Your Files</h3>
      <form onSubmit={handleFileUpload} encType="multipart/form-data">
        <input type="file" name="file" required />
        <button type="submit">Upload and Index</button>
      </form>

      <h3>Indexed Files</h3>
      <ul>
        {files.map((file, index) => (
          <li key={index}>
            {file}
            <button onClick={() => handleFileDelete(file)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FileUpload;

